import * as React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Seo from "../../components/seo.js";
import * as productStyle from "../../styles/product.module.scss";
import "../../styles/buttons.scss";

const Product = (props) => {
  const image = getImage(props.data.mdx.frontmatter.image);

  return (
    <Layout headOneOfPage={props.data.mdx.frontmatter.image_alt}>
      <Seo title={props.data.mdx.frontmatter.title} lang="hu" />
      <figure className={productStyle.container}>
        <div className={productStyle.imageContainer}>
          <GatsbyImage
            className={productStyle.image}
            image={image}
            alt={props.data.mdx.frontmatter.image_alt}
          />
        </div>

        <div className={productStyle.description}>
          <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
          <button className="buttons">
            <Link className="buttonLink" to="https://www.okosbazar.hu">
              IRÁNY A BOLT
            </Link>
          </button>
        </div>
      </figure>
    </Layout>
  );
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        image_alt
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`;
export default Product;
